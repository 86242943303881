import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextLink = ({ children, className, ...props }) => {
  const classes = classnames('TextLink', className);

  return (
    <a className={classes} {...props}>
      {children}
    </a>
  );
};

TextLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TextLink;
