import React from 'react';

import Layout from '../components/layout';
import TextLink from '../components/text-link';

const IndexPage = () => (
  <Layout>
    <div className="flex flex-col flex-1 justify-center w-full md:w-1/2">
      <div>
        <h1 className="font-bold text-3xl md:text-5xl">Hi,</h1>

        <div className="text-2xl md:text-4xl py-10">
          I'm Kasparas Galdikas, a{' '}
          <span className="font-bold">Full Stack Engineer</span> from Brighton,
          UK.
        </div>

        <TextLink href="mailto:info@kasparasg.com">Contact me</TextLink>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
